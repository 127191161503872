export const config = {
    url: {
        PUBLIC: 'https://public.takeaseat.io', // 'http://localhost:3003', //
        API: 'https://api.takeaseat.io', // 'http://localhost:3002', // 
        BOOKING_API: 'https://booking-api.takeaseat.io'
        // API: window._env_.API_URL,
        // GUEST: window._env_.GUEST_URL,
        // PUBLIC: window._env_.PUBLIC_URL,
        // LANDING: window._env_.LANDING_URL,
        // ADMIN: window._env_.ADMIN_URL,
        // PUBLICPAGE: window._env_.WEBSITE_URL,
        // BOOKING_API: window._env_.BOOKING_API_URL
    }
}
